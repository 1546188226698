export default {
    home: {
        nav:{
            sver:'服务',
            sver2:'我们是谁',
            sver3:'行业',
            about:'关于航特',
            about2:'我们的组织',
            about3:'大中华区',
            job:'求职',
            job2:'加入我们',
            job3:'搜索职位'
        },
        serviceList: [
            '应用服务',
            '多元宇宙',
            '人工智能',
            '数字化开发与制造',
            '自动化',
            '基础设施',
            '业务流程外包',
            '营销',
            '商业战略',
            '兼并与收购',
            '人才与组织绩效/员工潜能',
            '运营模式',
            '云服务',
            '安全服务',
            '客户体验',
            '供应链与运营',
            '大数据分析',
            '可持续',
            '生态合作',
            '技术咨询',
            'CFO与企业价值',
            '技术创新',
            '数字商务',
            '零基预算管理',
        ],
        industryList : [
            '汽车',
            '货运及物流',
            '通信和媒体',
            '工业设备',
            '公共服务',
            '保险业',
            '化工',
            '航天和国防',
            '高科技',
            '旅游',
            '消费品和服务',
            '银行业',
            '资本市场',
            '零售',
            '能源',
            '医疗',
            '生命科学',
            '软件与平台',
            '公共事业',
            '自然资源',
        ],
        aboutUse : [
            '航特咨询',
            '公司领导',
            '可持续',
            '国际新闻中心',
            '投资者关系',
            '包容性与多样性',
        ],
        aboutFrom : [
            '战略&咨询',
            'Song',
            '技术服务',
            '智能运营',
            '工业X',
        ],
        aboutCan : [
            '航特咨询中国',
            '新闻中心',
            '展望',
            '航特咨询技术研究院',
        ],
        jobUse : [
            '查询与申请',
            '社会招聘',
            '校园招聘',
            '培训',
            '执行高管',
        ],
        jobPosition : [
            '按专业领域搜索职位',
            '技术服务',
            '咨询',
            '应用智能',
            '职能部门',
            '云服务',
            '数字服务',
            'SAP服务',
            '智能运营',
            '数字化开发与制造',
            '战略',
            '技术架构',
        ],
        unit:{
            txt1:'重',
            txt2:'塑',
            txt3:'业',
            txt4:'务',
            txt5:'卓',
            txt6:'越',
            txt7:'致',
            txt8:'远',
            txt9:'应变于新，启运未来',
        },
        gird:{
            txt1:'研究报告',
            txt2:'企业展望2024',
            txt3:'华文彩云是一种常用的中文字体。这种字体与方正姚体、华文琥珀、华文隶书等多种字体并列，都是华文系列中的一部分。华文彩云字体设计精美，适用于多种场合，如广告、宣传、出版等，具有很高的辨识度和视觉冲击力。',
            txt4:'了解更多 》',
        },
        gird1:{
            txt1:'研究报告',
            txt2:'企业展望2024',
            txt3:'技术正在变得更加人性化，它与人类之间的交互越来越自然，用户界面更直观，能够理解和响应人类需求，并且与我们的日常生活无缝融合。积极布局并推动前沿科技商业化落地的企业组织将占领未来竞争制高点。',
            txt4:'了解更多 》',
        },
        gird2:{
            txt1:'研究报告',
            txt2:'打造韧性企业 开创增长新局',
            txt3:'企业必须果断行动，打造韧性，重塑供应和制造全价值链能力，从而打开增长新格局。',
            txt4:'了解更多 》',
        },
        gird3:{
            txt1:'洞见',
            txt2:'人人可用的生成式人工智能',
            txt3:'基于语言的人工智能飞速发展，正在开启一个企业全面重塑的新时代。这些新技术将改变商界、学术界甚至整个社会，为人类创造力和生产力带来积极影响。',
            txt4:'了解更多 》',
        },
        gird4:{
            txt1:'研究报告',
            txt2:'重塑长',
            txt3:'中国企业要想增强韧性、持续增长，成为世界一流企业，需要启动企业全面重塑战略。埃森哲自2018年开展中国企业数字化转型指数研究，连续追踪企业的数字化成熟度和转型历程。\n' +
                '伴随商业环境的变化和数字技术的创新，埃森哲今年对数字化转型指数框架进行了升级，全新定义企业重塑的五大关键能力。',
            txt4:'了解更多 》',
        },
        gird5:{
            txt1:'研究报告',
            txt2:'企业全面重塑：全新绩效前沿',
            txt3:'充分秉承“企业可持续重塑”战略的组织正在赢得更稳健的财务绩效、更亮丽的创新、更强大的韧性，同时为利益相关方创造更重要的价值。',
            txt4:'了解更多 》',
        },
        gird6:{
            txt1:'研究报告',
            txt2:'未来生活趋势2024',
            txt3:'五大趋势为企业在变化之境中指明方向',
            txt4:'了解更多 》',
        },
        gird7:{
            txt1:'洞见',
            txt2:'五大新动能',
            txt3:'为了驾驭这种复杂而多变的商业环境，企业高管们需要把握五大新动能',
            txt4:'了解更多 》',
        },
        gird8:{
            txt1:'研究报告',
            txt2:'重塑企业运营',
            txt3:'研究指出，企业应如何提升业务成熟度和运营效率，创造更多业务价值。',
            txt4:'了解更多 》',
        },
        days:'每天，我们都在拥抱变革，并为世界各地的所有利益相关者创造价值。',
        coreVal1:{
            txt1:'生成式人工智能正在改变客户服务',
            txt2:'每天筛选成千上万的电子邮件是一项乏味的工作，但生成式人工智能使其变得轻松。一家跨国银行正在使用生成式人工智能快速处理和回复客户的电子邮件，这些邮件用于交易的结算、资产服务和对账——通常这是一个庞大的手工任务。生成式人工智能能够将电子邮件转发给合适的团队，并为员工起草回复邮件。结果？更快速、更令人愉快的客户服务，提高了员工效率，同时也使运营更具成本效益。'
        },
        coreVal2:{
            txt1:'元宇宙与公共利益的融合',
            txt2:'世界经济论坛、埃森哲和微软在元宇宙中构建了一个目标导向的平台，以推动行动与合作，彻底革新进步。'
        },
        coreVal3:{
            txt1:'云上全球运营',
            txt2:'得益于数据赋能供应链控制塔，云计算的快速发展极大地推动了微软的供应链变革。这不仅提高了供应链的效率和透明度，还增强了对市场变化的响应能力。通过云技术的支持，微软能够更精准地预测需求，优化库存管理，确保全球各地的客户都能及时获得所需产品。这一变革使得微软在供应链管理上处于领先地位，为其持续创新和业务扩展奠定了坚实基础。'
        },
        earth:'全球赞誉',
        earthVal:{
            txt1:'全球最赞誉的公司',
            txt2:'连续17年入选科技先锋中国十大企业'
        },
        earthVal1:{
            txt1:'具有商业道德的企业',
            txt2:'做有商业道德的企业',
            txt3:'我们致力于做正确的事，积极向善，身体力行。“道德村”协会表彰那些坚持道德实践、对员工、社区及利益相关者产生积极影响并推动可持续商业发展的公司。'
        },
        earthVal2:{
            txt1:'最佳职场',
            txt2:'位列全球最佳职场™榜单',
            txt3:'这一认可基于我们员工的反馈，主要衡量他们在工作中的信任度、自豪感和同事间的友爱。'
        },
        earthVal3:{
            txt1:'有影响力的创新者',
            txt2:'有影响力的领导者',
            txt3:'我们坚持以人才为核心，以创新为驱动，助力全球领先企业持续重塑。'
        },
        img:{
            txt1:'加入我们吧',
            txt2:'未来,因你而变',
            txt3:'求职',
            txt4:'拥抱变化,发展职业',
            txt5:'求新、求知、求变、在航特咨询绽放光芒',
            txt6:'加入我们'
        },
        finish:{
            txt1:'应变于新 启运未来',
            txt2:'解释权：归航特咨询所有',
        },
        finishList: [
            "求职",
            "隐私政策",
            "关于航特咨询",
            "使用条款",
            "联系我们",
            "Cookie Policy/Settings",
            "航特者全球办公室名录",
            "粤ICP备19093402号",
            "网站地址",
            // "粤公网安备310516469816161号",
          ],
    },
  };

