import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import ELEMENT from 'element-ui'
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
import $ from 'jquery';
import axios from 'axios'
import moment from 'moment' //导入文件
import i18n from "@/lang"; 

Vue.prototype.$moment = moment;//赋值使用
Vue.prototype.$axios = axios;
window.jQuery = $;
window.$ = $;

Vue.config.productionTip = false;
// Vue.prototype.$EventBus = new Vue()
Vue.use(ELEMENT,
  {
    i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
  }
)
Vue.use(ElementUI);
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
