export default {
    home: {
        nav: {
            sver: 'service',
            sver2: 'Who are we',
            sver3: 'industry',
            about: 'About',
            about2: 'Our organization',
            about3: 'Greater China',
            job: 'Job',
            job2: 'Join us',
            job3: 'Search for position'
        },
        serviceList: [
            'application service',
            'Multiverse',
            'AI Plus',
            'Digital development and manufacturing',
            'automation',
            'Infrastructure',
            'Business process outsourcing',
            'Marketing',
            'Business strategy',
            'Mergers and Acquisitions',
            'Talent and organizational performance/employee potential',
            'Operation mode',
            'Cloud services',
            'Security Services',
            'customer experience ',
            'Supply Chain and Operations',
            'Big data analysis',
            'sustainable',
            'Ecological cooperation',
            'Technical consultation',
            'CFO and Enterprise Value',
            'technological innovation',
            'Digital commerce',
            'Zero based budget management',
        ],
        industryList: [
            'Cars',
            'Freight and logistics',
            'Communication and Media',
            'Industrial equipment',
            'Public services',
            'Insurance industry',
            'Chemical industry',
            'Aerospace and National Defense',
            'High tech',
            'Travel',
            'Consumer goods and services',
            'Banking industry',
            'Capital markets',
            'Retail',
            'Energy',
            'Medical',
            'Life Sciences',
            'Software and Platform',
            'Public utilities',
            'Natural resources',
        ],
        aboutUse: [
            'Regarding Essenta',
            'Company leaders',
            'Sustainable',
            'International News Center',
            'Investor Relations',
            'Inclusion and diversity',
        ],
        aboutFrom: [
            'Strategy&Consulting',
            'Song',
            'Technical services',
            'Intelligent operation',
            'Industrial X',
        ],
        aboutCan: [
            'Essenta China',
            'News Center',
            'Outlook',
            'Essenta Technology Research Institute',
        ],
        jobUse: [
            'Query and Application',
            'Social recruitment',
            'Campus Recruitment',
            'Training',
            'Executive executives',
        ],
        jobPosition: [
            'Search for positions by professional field',
            'Technical services',
            ' consulting service,',
            'Application Intelligence',
            'Functional departments',
            'Cloud services',
            'Digital Services',
            'SAP Services',
            'Intelligent operation',
            'Digital development and manufacturing',
            'Strategy',
            'Technical architecture',
        ],
        unit: {
            txt1: '重',
            txt2: '塑',
            txt3: '业',
            txt4: '务',
            txt5: '卓',
            txt6: '越',
            txt7: '致',
            txt8: '远',
            txt9: '文字占位123456',
        },
        gird: {
            txt1: 'This is the first line',
            txt2: 'This is the second row Aston Aston',
            txt3: 'This is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content',
            txt4: 'Learn more 》',
        },
        gird1: {
            txt1: 'This is the first line',
            txt2: 'This is the second row Aston Aston',
            txt3: 'This is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content',
            txt4: 'Learn more 》',
        },
        gird2: {
            txt1: 'This is the first line',
            txt2: 'This is the second row Aston Aston',
            txt3: 'This is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content',
            txt4: 'Learn more 》',
        },
        gird3: {
            txt1: 'This is the first line',
            txt2: 'This is the second row Aston Aston',
            txt3: 'This is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content',
            txt4: 'Learn more 》',
        },
        gird4: {
            txt1: 'This is the first line',
            txt2: 'This is the second row Aston Aston',
            txt3: 'This is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content',
            txt4: 'Learn more 》',
        },
        gird5: {
            txt1: 'This is the first line',
            txt2: 'This is the second row Aston Aston',
            txt3: 'This is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content',
            txt4: 'Learn more 》',
        },
        gird6: {
            txt1: 'This is the first line',
            txt2: 'This is the second row Aston Aston',
            txt3: 'This is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content',
            txt4: 'Learn more 》',
        },
        gird7: {
            txt1: 'This is the first line',
            txt2: 'This is the second row Aston Aston',
            txt3: 'This is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content',
            txt4: 'Learn more 》',
        },
        gird8: {
            txt1: 'This is the first line',
            txt2: 'This is the second row Aston Aston',
            txt3: 'This is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content, this is the content',
            txt4: 'Learn more 》',
        },
        days: 'Every day, we embrace change and create value for all stakeholders around the world。',
        coreVal1:{
            txt1:'Generative AI transforming customer service',
            txt2:'Sifting through thousands of emails daily is tedious work, but generative AI makes it easy. A multinational bank is using generative AI to quickly process and respond to client emails used for the settling, asset servicing and reconciliation of trades – normally a massive manual task. It directs emails to the right teams and drafts responses for employees to check. The result? Faster and more enjoyable customer service, a boost in worker efficiency and more cost-effective operations.'
        },
        coreVal2:{
            txt1:'The metaverse meets public good',
            txt2:'The World Economic Forum, Accenture and Microsoft have built a purpose-driven platform in the metaverse to drive action and cooperation and to revolutionize progress.'
        },
        coreVal3:{
            txt1:'Global Operations in the Cloud',
            txt2:'Thanks to data-powered supply chain control towers, the rapid development of cloud computing has greatly accelerated Microsoft\'s supply chain transformation. This has not only improved the efficiency and transparency of the supply chain but also enhanced responsiveness to market changes. With the support of cloud technology, Microsoft can more accurately forecast demand, optimize inventory management, and ensure timely delivery of products to customers around the world. This transformation positions Microsoft as a leader in supply chain management, laying a solid foundation for ongoing innovation and business expansion.'
        },
        earth: 'Global acclaim',
        earthVal: {
            txt1: "The world's most acclaimed company",
            txt2: 'This is the content of praise00745612359856064561231616164646546461'
        },
        earthVal1: {
            txt1: 'Enterprises with Business Ethics',
            txt2: 'Being an Enterprise with Business Ethics',
            txt3: 'We are committed to doing the right thing, promoting good, and leading by example. The "Ethical Village" association recognizes companies that uphold ethical practices, positively impact employees, communities, and stakeholders, and drive sustainable business development.'
        },
        earthVal2: {
            txt1: 'Best Workplace',
            txt2: 'Ranked on the Global Best Workplaces™ List',
            txt3: 'This recognition is based on feedback from our employees, primarily measuring their trust, pride in their work, and camaraderie with colleagues.'
        },
        earthVal3: {
            txt1: 'Impactful Innovators',
            txt2: 'Influential Leaders',
            txt3: 'We are committed to talent-centered and innovation-driven approaches, helping leading global enterprises continually reinvent themselves.'
        },
        img: {
            txt1: 'Join us now',
            txt2: 'The future will change because of you',
            txt3: 'Job seeking',
            txt4: 'Embrace change and develop careers',
            txt5: 'Seeking novelty, knowledge, change, and shining in Essenta',
            txt6: 'Join us,'
        },
        finish: {
            txt1: 'Adapt to the new and promising future',
            txt2: 'Interpretation rights: owned by *********',
        },
        finishList: [
            "Job search",
            "Privacy Policy",
            'Regarding HtConsult',
            "Terms of Use",
            "Contact us",
            "Cookie Policy/Settings",
            "Essence Global Office Directory",
            "guangdong ICP Preparation No. 19093402",
            "Website address",
            // "Yue Gong Wang An Bei No. 31010102006676",
        ],
    },
};

