import VueI18n from "vue-i18n";
import Vue from "vue";

Vue.use(VueI18n);

//  引入需要的语言包
import en from "@/lang/en.js";
import zh from "@/lang/zh.js";

// 本地语言环境获取
const lang = localStorage.getItem("language") || navigator.language || "zh";

const i18n = new VueI18n({
  locale: lang, // 语言标识
  fallbackLocale: "zh-CN", // 失败时默认语言
  silentTranslationWarn: true, // 设置为true 会屏蔽翻译在控制台报出的警告
  messages: {
    en: en,
    "zh-CN": zh,
  },
});


export default i18n;

